import request from '@/utils/request';

//退出登录
export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  });
}

//手机号登录
export function loginBySms(data) {
  return request({
    url: '/litigant/sms/loginBySms',
    method: 'post',
    data: data
  });
}

// 查询手机号是否存在
export function hasPhone(data) {
  return request({
    url: '/litigant/sms/accountIsExist',
    method: 'post',
    data: data
  });
}

//生成图形验证码
export function captcha() {
  return request({
    url: '/litigant/captcha',
    method: 'get',
    responseType: 'arraybuffer'
  });
}

//发送短信
export function sendSms(data) {
  return request({
    url: '/litigant/sms/sendSms',
    method: 'post',
    data: data
  });
}

// 无需图形验证码的短信发送
export function sendSmsBynoImgCode(data) {
  return request({
    url: '/litigant/sms/sendNoCaptchaSms',
    method: 'post',
    data: data
  });
}

//获取案号年份
export function getNowDate() {
  return request({
    url: '/litigant/getNowDate',
    method: 'get'
  });
}

//旧接案注册
// export function registerByCase(data) {
//   return request({
//     url: '/litigant/registerByCase',
//     method: 'post',
//     data
//   })
// }

//新接案注册
export function registerByCase(data) {
  return request({
    url: '/litigant/registerByIdentity',
    method: 'post',
    data: data
  });
}

//文件上传
export function uploadImg() {
  return '/litigant/fileUpload/uploadImg';
}

//实名认证
export function addIdentityInfo(data) {
  return request({
    url: '/litigant/userIdentityInfo/addIdentityInfo',
    method: 'post',
    data: data
  });
}

//查看实名认证信息
export function getIdentityInfo() {
  return request({
    url: '/litigant/userInfo/findIdentityInfo',
    method: 'get'
  });
}