import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import { getToken, removeToken } from "@/utils/auth";
import router from "@/router";
import { encryptByAES, decryptByAES } from "@/utils/security";
import { encryptKey } from "@/config/encryptKey";
var CryptoJS = require("crypto-js");

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url    （提交时改这个）
  // baseURL: '/api',
  withCredentials: true,
  // send cookies when cross-domain requests(跨域请求时发送cookie)
  timeout: 10000,
  // request timeout
  headers: {
    post: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  }
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (getToken) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers["Authorization"] = getToken();
  }
  console.log("原始数据:", config.data);
  console.log("接口地址：", config.url);
  //AES对请求数据加密处理
  config.data = {
    request: encryptByAES(JSON.stringify(config.data), encryptKey.key)
  };
  config.params = {
    request: encryptByAES(JSON.stringify(config.params), encryptKey.key)
  };
  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(function (response) {
  // AES数据解密处理
  console.log(response);
  if (response.data.response) {
    var resp = decryptByAES(response.data.response, encryptKey.key);
    response.data = JSON.parse(resp);
    console.log("解密之后数据,", response.data);
  }
  var res = response.data;
  if (res.code == "10002" || res.code == "10001" || res.code == "10000") {
    Message({
      message: res.msg + "请重新登录",
      type: "error",
      duration: 2 * 1000
    });
    // re-login
    store.dispatch("user/resetToken").then(function () {
      location.reload();
    });
  }
  return res;
}, function (error) {
  Message({
    message: error.message,
    type: "error",
    duration: 2 * 1000
  });
  return Promise.reject(error);
});
export default service;